import React, {useState, useEffect, useRef} from 'react';
import {InputText} from 'primereact/inputtext';
import {InputNumber} from 'primereact/inputnumber';
import {AutoComplete} from 'primereact/autocomplete';
import {Calendar} from 'primereact/calendar';
import {Chips} from 'primereact/chips';
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect';
import {RapportService} from "../../service/rapports/RapportService";
import {Tooltip} from "primereact/tooltip";
import {Button} from "primereact/button";
import {SouscriptionService} from "../../service/operations/SouscriptionService";
import {ProgressSpinner} from "primereact/progressspinner";
import {ProgressBar} from "primereact/progressbar";
import { Toast } from 'primereact/toast';
import { InputSwitch } from "primereact/inputswitch";
import {EleveService} from "../../service/operations/EleveService";
import {ClasseService} from "../../service/ClasseService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {FilterMatchMode} from "primereact/api";
import { Card } from 'primereact/card';
import {Panel} from "primereact/panel";
import { RadioButton } from 'primereact/radiobutton';


const DocumentsImprimables = () => {

    let emptyAnne = {
        id :null,
        code :'' ,
        libelle :''
    };
    let empty = {
        id :'',
        code :null ,
        libelle :null
    };
    let emptyInfos4 = {
        id:'',
        libelle:''
    };
    let niveauMat = {
        niveau:''
    };
    let emptystatutEleve={
        statut_code:'',
        statut_libelle:''
    }
    let emptySexe= {
        sexe_code: '' ,
        sexe_libelle: ''
    } ;
    let emptyMatiere = {
        id: null
    }
    let emptyRapport = {
        code :null,
        nom :'' ,
        libelle_Fonction :'',
        descriptions:''
    };
    const toast = useRef(null);
    const [tenant, settenant]  = useState(sessionStorage.getItem('EcoleID'));
    const [niveauEnseign, setNiveauEnseign]  = useState(sessionStorage.getItem('niveauEnseignementId'));
    const [annee, setannee] = useState(empty);
    const [baseUri, setbaseUri] = useState(window.$baseUri);
    const [annees, setannees] = useState(null);
    const [periode, setPeriode] = useState(empty);
    const [periodes, setPeriodes] = useState(null);
    const [classe, setClasse] = useState(empty);
    const [classes, setClasses] = useState(null);
    const [branches, setBranches]  = useState(null);
    const [branche, setBranche]  = useState(emptyInfos4);
    const [codeEcole, setCodeEcole] = useState('01');
    const [redoublant, setRedoublant] = useState(empty);
    const [boursier, setBoursier] = useState(empty);
    const [langue_vivante, setLangue_vivante] = useState(empty);
    const [statutEleve, setstatutEleve]  = useState(emptystatutEleve);
    const [selectedSexe, setSelectedSexe] = useState(emptySexe);
    const [niveauMatricule, setNiveauMatricule] = useState();
    const [Listmatricule, setListmatricule] = useState([]);
    const [spin, setSpin] = useState(null);
    const [value, setValue] = useState(0);
    const interval = useRef(null);
    const [loading, setLoading] = useState(false);
    const [isNotSpinning, setIsNotSpinning] = useState('hidden');
    const [rapports, setRapports] = useState(null);
    const [compress, setCompress] = useState(true);
    const [positionLog, setPositionLog] = useState(true);
    const [filigramme, setFiligramme] = useState(true);
    const [checked, setChecked] = useState(false);
    const [photo, setPhoto] = useState(false);
    const [fonction, setFonction] = useState('');
    const [matricule, setmatricule] = useState('');
    const [moyenne, setmoyenne] = useState(null);
    const [lastperiode, setlastperiode] = useState('Troisième Trimestre');
    const [signataire, setSignataire] = useState(null);
    const [matiere, setMatiere] = useState(emptyMatiere);
    const [matieres, setMatieres] = useState([]);
    const anneeEncours = sessionStorage.getItem('AnneEncours');
    const [rapport, setRapport] = useState(emptyRapport);
    const [globalFilterValue2, setGlobalFilterValue2] = useState('');
    const [pivoter, setPivoter] = useState(true);
    const [distinction, setDistinction] = useState(true);
    const [infosAmoirie, setInfosAmoirie] = useState(true);
    const [modelePointCarre, setModelePointCarre] = useState(false);
    const [modelelmd, setModelelmd] = useState(false);
    const [testLourd, setTestLourd] = useState(false);
    const [bulletinArabe, setBulletinArabe] = useState(false);
    const [autreModelFreque, setAutreModelFreque] = useState(false);
    const [modeleRapport, setModeleRapport] = useState('dren3');
    const [filters2, setFilters2] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'code':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'nom':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'description':{ value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [niveau, setNiveau] = useState('');

    useEffect(() => {
        const  rapp= new RapportService() ;
        rapp.getListClasseParEcole(tenant).then(data => setClasses(data));
        rapp.listPeriode().then(data => setPeriodes(data));
        rapp.listAnnee(tenant).then(data => setannees(data));
        rapp.listRapports(niveauEnseign).then(data => setRapports(data));
        const eleveService = new EleveService();
        eleveService.listBrancheByEcole(tenant).then(data => {
            console.log(data)
            setBranches(data)
        });


    }, []);
    useEffect(() => {
        console.log("Model Rapport "+modeleRapport) ;
    });

    useEffect(() => {
        if(classe!=null) {
            const classeService = new ClasseService();
            classeService.getListMatierByEcoleClasseWeb(sessionStorage.getItem("EcoleID"),classe.id).then(data => setMatieres(data));

        }

    }, [classe]);

    const getActeurLabel = () => {
        const niveauEnseign1 = parseInt(niveauEnseign, 10); // S'assure que c'est un nombre
        console.log('Valeur de niveauEnseign++:', niveauEnseign1);
        return niveauEnseign1 > 2 && niveauEnseign1 !== 4 ? 'Etudiants' : 'Elèves';
    };

    const getActeurLabels = () => {
        const niveauEnseign1 = parseInt(niveauEnseign, 10); // S'assure que c'est un nombre
        console.log('Valeur de niveauEnseign++:', niveauEnseign1);
        return niveauEnseign1 > 2 && niveauEnseign1 !== 4 ? 'Etudiants' : 'Elèves';
    };






    const Sexe = [
        {sexe_code: 'MASCULIN', sexe_libelle: 'MASCULIN'},
        {sexe_code: 'FEMININ', sexe_libelle: 'FEMININ'}

    ];

    const Statuts = [
        {statut_code: 'AFFECTE', statut_libelle: 'AFFECTE'},
        {statut_code: 'NON_AFFECTE', statut_libelle: 'NON AFFECTE'},
    ];
    const LV2S = [
        {id: 'ALL', libelle: 'ALL'},
        {id: 'ESP', libelle: 'ESP'}

    ];
    const Redoublants = [
        {id: 'NON', libelle: 'NON'},
        {id: 'OUI', libelle: 'OUI'}
    ];
    const Boursiers = [

        {id: 'B', libelle: 'B'},
        {id: '1/2B', libelle: '1/2B'}


    ];
    const functions = {
        R05 :() => {
            const rapportService = new RapportService();
            setIsNotSpinning('')
            fetch(baseUri+"imprimer-rapport/pouls-rapport/"+tenant+"/docx/"+annee.libelle+"/"+periode.libelle+"/"+annee.id+"/"+modeleRapport)
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'rapport-trimestrielle'+'_'+'_'+periode.libelle+'.docx';
                        a.click();
                    });
                    setIsNotSpinning('hidden')
                });


        },
        R02 : () => {
            setIsNotSpinning('');

            fetch(baseUri+"imprimer-bulletin-list/spider-bulletin-matricule/"+tenant+"/"+periode.libelle+"/"+annee.libelle+"/"+classe.id+"/"+matricule+"/"+compress+"/"+niveauEnseign +"/"+positionLog+"/"+filigramme+"/"+infosAmoirie+"/"+pivoter+"/"+modelePointCarre+"/"+distinction+"/"+modelelmd+"/"+testLourd+"/"+bulletinArabe)
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'Bulletin'+'_'+matricule+'_'+periode.libelle+'.pdf';
                        a.click();
                    });
                    setIsNotSpinning('hidden');
                    //window.location.href = response.url;
                });

        } ,
        R08 : () => {
            setIsNotSpinning('');

            fetch(baseUri+"imprimer-livret-list/spider-livret-matricule/"+tenant+"/"+periode.libelle+"/"+annee.libelle+"/"+classe.libelle+"/"+matricule+"/"+positionLog+"/"+filigramme+"/"+infosAmoirie+"/"+pivoter+"/"+distinction)
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'Livret Soclaire'+'_'+periode.libelle+'.pdf';
                        a.click();
                        setIsNotSpinning('hidden');
                    });

                    //window.location.href = response.url;
                });

        } ,
        R01 : () => {
            const rapportService = new RapportService();
            setIsNotSpinning('');

            fetch(baseUri+"imprimer-bulletin-list/spider-bulletin/"+tenant+"/"+periode.libelle+"/"+annee.libelle+"/"+classe.id+"/"+compress+"/"+niveauEnseign+"/"+positionLog+"/"+filigramme+"/"+infosAmoirie+"/"+pivoter+"/"+modelePointCarre +"/"+distinction+"/"+modelelmd+"/"+testLourd+"/"+bulletinArabe)
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'Bulletin'+'_'+periode.libelle+'.pdf';
                        a.click();
                        setIsNotSpinning('hidden');
                    });

                    //window.location.href = response.url;
                });

        } ,
        R03 : () => {

            const rapportService = new RapportService();
            setIsNotSpinning('')
            fetch(baseUri+"imprimer-rapport-dsps/pouls-rapport-dsps/"+tenant+"/"+periode.libelle+"/"+annee.libelle)
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'fichier_dspd'+'_'+'_'+periode.libelle+'.xls';
                        a.click();
                    });
                    setIsNotSpinning('hidden')
                });


        } ,
        R04 : () => {

            const rapportService = new RapportService();
            setIsNotSpinning('')
            fetch(baseUri+"imprimer-rapport-cio/pouls-rapport-cio/"+tenant+"/"+annee.libelle+"/"+periode.libelle)
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'rapport cio-trimestrielle'+'_'+'_'+periode.libelle+'.xls';
                        a.click();
                    });
                    setIsNotSpinning('hidden')
                });


        } ,

        R06 : () => {
            const rapportService = new RapportService();
            setIsNotSpinning('')
            fetch(baseUri+"imprimer-rapport-annuelle/pouls-rapport/"+tenant+"/docx/"+annee.libelle+"/Troisième Trimestre")
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'rapport-annuelle'+'_'+'_'+periode.libelle+'.docx';
                        a.click();
                    });
                    setIsNotSpinning('hidden')
                });
        } ,
        R07 : () => {
            const rapportService = new RapportService();
            setIsNotSpinning('');

            setIsNotSpinning('');

            fetch(baseUri+"imprimer-livret-list/spider-livret/"+tenant+"/"+periode.libelle+"/"+annee.libelle+"/"+classe.libelle+"/"+positionLog+"/"+filigramme+"/"+infosAmoirie+"/"+pivoter+"/"+distinction)
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'Livret Soclaire'+'_'+periode.libelle+'.pdf';
                        a.click();
                        setIsNotSpinning('hidden');
                    });

                    //window.location.href = response.url;
                });

        } ,

        R25 : () => {
            const rapportService = new RapportService();
            setIsNotSpinning('');

            fetch(baseUri+"imprimer-trois-premiers/par-classe/"+tenant+"/"+periode.libelle+"/"+annee.libelle+"/"+classe.id)
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'Liste trois premiers par classe'+'_'+periode.libelle+'.pdf';
                        a.click();
                        setIsNotSpinning('hidden');
                    });

                    //window.location.href = response.url;
                });

        } ,
        R29 : () => {
            const rapportService = new RapportService();
            setIsNotSpinning('');

            fetch(baseUri+"imprimer-trois-premiers/par-classe-annuelle/"+tenant+"/"+periode.libelle+"/"+annee.libelle+"/"+classe.id)
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'Liste trois premiers par classe'+'_'+periode.libelle+'.pdf';
                        a.click();
                        setIsNotSpinning('hidden');
                    });

                    //window.location.href = response.url;
                });

        } ,
         R26 : () => {
            const rapportService = new RapportService();
            setIsNotSpinning('');

            fetch(baseUri+"imprimer-trois-premiers/par-niveau/"+tenant+"/"+periode.libelle+"/"+annee.libelle+"/"+branche.id)
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'Liste trois premiers par niveau'+'_'+periode.libelle+'.pdf';
                        a.click();
                        setIsNotSpinning('hidden');
                    });
                    //window.location.href = response.url;
                });

        },
        R30 : () => {
            const rapportService = new RapportService();
            setIsNotSpinning('');

            fetch(baseUri+"imprimer-trois-premiers/par-niveau-annuelle/"+tenant+"/"+periode.libelle+"/"+annee.libelle+"/"+branche.id)
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'Liste trois premiers par niveau'+'_'+periode.libelle+'.pdf';
                        a.click();
                        setIsNotSpinning('hidden');
                    });
                    //window.location.href = response.url;
                });

        },
         R24 : () => {
            const rapportService = new RapportService();
            setIsNotSpinning('');

            fetch(baseUri+"moyenneProf/"+classe.id+"/"+matiere.id+"/"+anneeEncours+"/"+periode.id)
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'Moyenne par professuer'+'_'+matiere.libelle+'.pdf';
                        a.click();
                        setIsNotSpinning('hidden');
                    });

                    //window.location.href = response.url;
                });

        },
         R23 : () => {
            const rapportService = new RapportService();
            setIsNotSpinning('');

            fetch(baseUri+"imprimer-tableau-honneur/spider-tableau/"+tenant+"/"+periode.libelle+"/"+annee.libelle+"/"+classe.id)
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'tableau honneur'+'_'+periode.libelle+'.pdf';
                        a.click();
                        setIsNotSpinning('hidden');
                    });

                    //window.location.href = response.url;
                });

        },
         R09 : () => {
            if(checked) {
                setIsNotSpinning('');

                fetch(baseUri+"imprimer-rapport-rentree-spider/xls/"+tenant +"/"+annee.id+"/"+annee.libelle)
                    .then(response => {
                        response.blob().then(blob => {
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement('a');
                            a.href = url;
                            a.download = 'Rapport de rentrée'+'.xls';
                            a.click();
                        });
                        setIsNotSpinning('hidden');
                        //window.location.href = response.url;
                    });
            } else {
                setIsNotSpinning('');

                fetch(baseUri+"imprimer-rapport-rentree-spider/"+tenant +"/"+annee.id+"/"+annee.libelle)
                    .then(response => {
                        response.blob().then(blob => {
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement('a');
                            a.href = url;
                            a.download = 'Rapport de rentrée'+'.docx';
                            a.click();
                        });
                        setIsNotSpinning('hidden');
                        //window.location.href = response.url;
                    });
            }


        } ,
         R22 : () => {
            const rapportService = new RapportService();
            setIsNotSpinning('')
            fetch(baseUri+"certificat-scolarite/certificat-de-frequentation/"+matricule+"/"+tenant+"/"+annee.libelle+"/"+signataire+"/"+fonction+"/"+autreModelFreque)
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'Certificat-frequentation'+matricule+'.docx';
                        a.click();
                    });
                    setIsNotSpinning('hidden')
                });

        } ,
      R10 : () => {

            const rapportService = new RapportService();
            setIsNotSpinning('')
            fetch(baseUri+"emploi-du-temps/imprimer/"+tenant+"/"+annee.id+"/"+classe.id)
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'Emploi-du-temps'+'_'+classe.libelle+'.pdf';
                        a.click();
                    });
                    setIsNotSpinning('hidden')
                });


        } ,
        R11  : () => {

            const rapportService = new RapportService();
            setIsNotSpinning('')
            fetch(baseUri+"imprimer-major-list/"+tenant+"/"+periode.libelle+"/"+annee.libelle)
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'Liste des majors par periode'+'_'+'_'+periode.libelle+'.pdf';
                        a.click();
                    });
                    setIsNotSpinning('hidden')
                });


        } ,
         R12 : () => {
            const rapportService = new RapportService();
            if(checked) {
                setIsNotSpinning('')
                fetch(baseUri+"imprimer-perspnnel/personnel-administratif-xls/"+tenant)
                    .then(response => {
                        response.blob().then(blob => {
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement('a');
                            a.href = url;
                            a.download = 'personnel-administratif'+'.xls';
                            a.click();
                        });
                        setIsNotSpinning('hidden')
                    });
            } else {
                setIsNotSpinning('')
                fetch(baseUri+"imprimer-perspnnel/personnel-administratif/"+tenant)
                    .then(response => {
                        response.blob().then(blob => {
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement('a');
                            a.href = url;
                            a.download = 'personnel-administratif'+'.pdf';
                            a.click();
                        });
                        setIsNotSpinning('hidden')
                    });


            }

        } ,
        R19 : () => {
            const rapportService = new RapportService();
            if(checked) {
                setIsNotSpinning('')
                fetch(baseUri+"imprimer-matrice-classe/imprimer-spider-xls/"+tenant+"/"+annee.libelle+"/"+periode.libelle+"/"+annee.id+"/"+classe.id)
                    .then(response => {
                        response.blob().then(blob => {
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement('a');
                            a.href = url;
                            a.download = 'matrice-classe'+classe.libelle+'.xls';
                            a.click();
                        });
                        setIsNotSpinning('hidden')
                    });
            } else {
                setIsNotSpinning('')
                fetch(baseUri+"imprimer-matrice-classe/imprimer-spider/"+tenant+"/"+annee.libelle+"/"+periode.libelle+"/"+annee.id+"/"+classe.id)
                    .then(response => {
                        response.blob().then(blob => {
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement('a');
                            a.href = url;
                            a.download = 'matrice-classe'+classe.libelle+'.pdf';
                            a.click();
                        });
                        setIsNotSpinning('hidden')
                    });


            }

        } ,
         R20 : () => {
            const rapportService = new RapportService();
            setIsNotSpinning('')
            fetch(baseUri+"imprimer-rapport-pv-conseil-classe/pouls-Conseil-classe/"+tenant+"/"+annee.libelle+"/"+periode.libelle+"/"+classe.libelle+"/"+annee.id+"/"+classe.id)
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'Proces-verbal-conseil-de-classe'+classe.libelle+'.pdf';
                        a.click();
                    });
                    setIsNotSpinning('hidden')
                });

        } ,
         R21 : () => {
            const rapportService = new RapportService();
            if(checked) {
                setIsNotSpinning('')
                fetch(baseUri+"imprimer-matrice-Annuelle/imprimer-spider-annuelle-xls/"+tenant+"/"+annee.libelle+"/"+periode.libelle+"/"+annee.id+"/"+classe.id)
                    .then(response => {
                        response.blob().then(blob => {
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement('a');
                            a.href = url;
                            a.download = 'matrice-Annuelle'+'.xls';
                            a.click();
                        });
                        setIsNotSpinning('hidden')
                    });
            } else {
                setIsNotSpinning('')
                fetch(baseUri+"imprimer-matrice-Annuelle/imprimer-spider-annuelle/"+tenant+"/"+annee.libelle+"/"+periode.libelle+"/"+annee.id+"/"+classe.id)
                    .then(response => {
                        response.blob().then(blob => {
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement('a');
                            a.href = url;
                            a.download = 'matrice-Annuelle'+'.pdf';
                            a.click();
                        });
                        setIsNotSpinning('hidden')
                    });


            }

        } ,

        R27 : () => {
            const rapportService = new RapportService();
            if(checked) {
                setIsNotSpinning('')
                fetch(baseUri+"imprimer-matrice-Annuelle/imprimer-spider-discpline-xls/"+tenant+"/"+annee.libelle+"/"+periode.libelle+"/"+annee.id+"/"+classe.id+"/"+matiere.libelle)
                    .then(response => {
                        response.blob().then(blob => {
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement('a');
                            a.href = url;
                            a.download = 'matrice-Annuelle'+'.xls';
                            a.click();
                        });
                        setIsNotSpinning('hidden')
                    });
            } else {
                setIsNotSpinning('')
                fetch(baseUri+"imprimer-matrice-Annuelle/imprimer-spider-discpline/"+tenant+"/"+annee.libelle+"/"+periode.libelle+"/"+annee.id+"/"+classe.id+"/"+matiere.libelle)
                    .then(response => {
                        response.blob().then(blob => {
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement('a');
                            a.href = url;
                            a.download = 'matrice-Annuelle'+'.pdf';
                            a.click();
                        });
                        setIsNotSpinning('hidden')
                    });


            }

        } ,

        R28 : () => {
            const rapportService = new RapportService();
            if(checked) {
                setIsNotSpinning('')
                fetch(baseUri+"imprimer-matrice-Annuelle/imprimer-spider-annuelle-dfa-xls/"+tenant+"/"+annee.libelle+"/"+periode.libelle+"/"+annee.id+"/"+classe.id)
                    .then(response => {
                        response.blob().then(blob => {
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement('a');
                            a.href = url;
                            a.download = 'matrice-Annuelle-avec dfa'+'.xls';
                            a.click();
                        });
                        setIsNotSpinning('hidden')
                    });
            } else {
                setIsNotSpinning('')
                fetch(baseUri+"imprimer-matrice-Annuelle/imprimer-spider-annuelle-dfa/"+tenant+"/"+annee.libelle+"/"+periode.libelle+"/"+annee.id+"/"+classe.id)
                    .then(response => {
                        response.blob().then(blob => {
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement('a');
                            a.href = url;
                            a.download = 'matrice-Annuelle avec dfa'+'.pdf';
                            a.click();
                        });
                        setIsNotSpinning('hidden')
                    });


            }

        } ,

        R13 : () => {
            const rapportService = new RapportService();
            if(checked) {
                setIsNotSpinning('')
                fetch(baseUri+"imprimer-perspnnel/personnel-enseignant-xls/"+annee.id+"/"+tenant)
                    .then(response => {
                        response.blob().then(blob => {
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement('a');
                            a.href = url;
                            a.download = 'personnel-enseignant'+'.xls';
                            a.click();
                        });
                        setIsNotSpinning('hidden')
                    });
            } else {
                setIsNotSpinning('')
                fetch(baseUri+"imprimer-perspnnel/personnel-enseignant/"+annee.id+"/"+tenant)
                    .then(response => {
                        response.blob().then(blob => {
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement('a');
                            a.href = url;
                            a.download = 'personnel-enseignant'+'.pdf';
                            a.click();
                        });
                        setIsNotSpinning('hidden')
                    });


            }

        } ,
         R14 : () => {
            const rapportService = new RapportService();
            if(checked) {
                setIsNotSpinning('')
                const  queryParams1 = [];
                queryParams1.push('anneeId='+annee.id);
                queryParams1.push('photo='+photo);
                if(tenant) {
                    queryParams1.push('IdEcole='+tenant);
                }

                if(statutEleve.statut_code){
                    queryParams1.push('affecte='+statutEleve.statut_code);
                }

                if(branche.id){
                    queryParams1.push('branche='+branche.id);
                }

                if(classe.libelle){
                    queryParams1.push('classe='+classe.libelle);
                }
                if(selectedSexe.sexe_code){
                    queryParams1.push('genre='+selectedSexe.sexe_code);
                }
                if(langue_vivante.idl){
                    queryParams1.push('langueVivante='+langue_vivante.id);
                }
                if(redoublant.id){
                    queryParams1.push('redoublant='+redoublant.id);
                }
                if(boursier.id){
                    queryParams1.push('boursier='+boursier.id);
                }




                const  queryString = queryParams1.length >0? queryParams1.join('&'):'';
                const  encode = encodeURIComponent(queryString)

                const apiUrl ='imprimer-perspnnel/eleve-par-classe?'+queryString;
                fetch(baseUri+"imprimer-perspnnel/eleve-par-classe-xls?"+queryString)
                    .then(response => {
                        response.blob().then(blob => {
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement('a');
                            a.href = url;
                            a.download = 'Liste des élèves'+'.xls';
                            a.click();
                        });
                        setIsNotSpinning('hidden')
                    });
            } else {
                setIsNotSpinning('')
                // console.log("anneeID "+annee.id)
                const  queryParams1 = [];
                queryParams1.push('anneeId='+annee.id);
                queryParams1.push('photo='+photo);
                if(tenant) {
                    queryParams1.push('IdEcole='+tenant);
                }

                if(statutEleve.statut_code){
                    queryParams1.push('affecte='+statutEleve.statut_code);
                }

                if(branche.id){
                    queryParams1.push('branche='+branche.id);
                }

                if(classe.libelle){
                    queryParams1.push('classe='+classe.libelle);
                }
                if(selectedSexe.sexe_code){
                    queryParams1.push('genre='+selectedSexe.sexe_code);
                }
                if(langue_vivante.idl){
                    queryParams1.push('langueVivante='+langue_vivante.id);
                }
                if(redoublant.id){
                    queryParams1.push('redoublant='+redoublant.id);
                }
                if(boursier.id){
                    queryParams1.push('boursier='+boursier.id);
                }


                const  queryString = queryParams1.length >0? queryParams1.join('&'):'';
                const  encode = encodeURIComponent(queryString)

                const apiUrl ='imprimer-perspnnel/eleve-par-classe?'+queryString;
                fetch(baseUri+apiUrl)
                    .then(response => {
                        response.blob().then(blob => {
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement('a');
                            a.href = url;
                            a.download = 'Liste des élèves'+'.pdf';
                            a.click();
                        });
                        setIsNotSpinning('hidden')
                    });


            }

        } ,
         R15 : () => {
            const rapportService = new RapportService();
            if(checked) {
                setIsNotSpinning('')
                const  queryParams1 = [];
                queryParams1.push('anneeId='+annee.id);
                if(tenant) {
                    queryParams1.push('IdEcole='+tenant);
                }
                if(moyenne) {
                    queryParams1.push('moyenne='+moyenne);
                }

                if(statutEleve.statut_code){
                    queryParams1.push('affecte='+statutEleve.statut_code);
                }

                if(branche.id){
                    queryParams1.push('branche='+branche.id);
                }

                if(classe.libelle){
                    queryParams1.push('classe='+classe.libelle);
                }
                if(selectedSexe.sexe_code){
                    queryParams1.push('genre='+selectedSexe.sexe_code);
                }



                const  queryString = queryParams1.length >0? queryParams1.join('&'):'';
                const  encode = encodeURIComponent(queryString)

                //const apiUrl ='imprimer-perspnnel/eleve-par-classe?'+queryString;
                fetch(baseUri+"imprimer-perspnnel/eleve-par-moyenne-xls?"+queryString)
                    .then(response => {
                        response.blob().then(blob => {
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement('a');
                            a.href = url;
                            a.download = 'Liste des élèves par moyenne'+'.xls';
                            a.click();
                        });
                        setIsNotSpinning('hidden')
                    });
            } else {
                setIsNotSpinning('')
                // console.log("anneeID "+annee.id)
                const  queryParams1 = [];
                queryParams1.push('anneeId='+annee.id);
                if(tenant) {
                    queryParams1.push('IdEcole='+tenant);
                }
                if(moyenne) {
                    queryParams1.push('moyenne='+moyenne);
                }
                if(statutEleve.statut_code){
                    queryParams1.push('affecte='+statutEleve.statut_code);
                }

                if(branche.id){
                    queryParams1.push('branche='+branche.id);
                }

                if(classe.libelle){
                    queryParams1.push('classe='+classe.libelle);
                }
                if(selectedSexe.sexe_code){
                    queryParams1.push('genre='+selectedSexe.sexe_code);
                }

                const  queryString = queryParams1.length >0? queryParams1.join('&'):'';
                const  encode = encodeURIComponent(queryString)

                const apiUrl ='imprimer-perspnnel/eleve-par-moyenne?'+queryString;
                fetch(baseUri+apiUrl)
                    .then(response => {
                        response.blob().then(blob => {
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement('a');
                            a.href = url;
                            a.download = 'Liste des élèves par moyenne'+'.pdf';
                            a.click();
                        });
                        setIsNotSpinning('hidden')
                    });


            }

        } ,
         R16 : () => {
            const rapportService = new RapportService();
            setIsNotSpinning('')
            fetch(baseUri+"certificat-scolarite/imprimer/"+tenant+"/"+matricule+"/"+annee.id+"/"+signataire+"/"+fonction+"/"+lastperiode)
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'Certificat-scolarité'+matricule+'.docx';
                        a.click();
                    });
                    setIsNotSpinning('hidden')
                });

        },
        R31 : () => {
            const rapportService = new RapportService();
            setIsNotSpinning('')
            fetch(baseUri+"imprimer-etats/liste-classe-arabe/"+classe.id+"/"+annee.id)
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'liste de classe'+classe.libelle+'.pdf';
                        a.click();
                    });
                    setIsNotSpinning('hidden')
                });

        },
        R32 : () => {
            const rapportService = new RapportService();
            setIsNotSpinning('')
            fetch(baseUri+"imprimer-proces-verbal/imprimer-proces-verbalv2/"+tenant+"/"+annee.libelle+"/"+periode.libelle+"/"+classe.id+"/"+classe.libelle)
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'Procès verbal de conseil de classe'+classe.libelle+'.pdf';
                        a.click();
                    });
                    setIsNotSpinning('hidden')
                });

        }

    }




























    const onBoursierChange = (e) => {
        // setBoursier(e.value);
        let _boursier= {...boursier} ;
        _boursier.id = e.value ;
        setBoursier(_boursier)

    } ;
    const onRedoublantChange = (e) => {
        //  setRedoublant(e.value);
        let _redoublant= {...redoublant} ;
        _redoublant.id = e.value ;
        setRedoublant(_redoublant)

    } ;
    const onSexeChange = (e) => {
        //setSelectedSexe(e.value);
        let _sexe= {...selectedSexe} ;
        _sexe.sexe_code = e.value ;
        setSelectedSexe(_sexe) ;

    }

    const onLangChange = (e) => {
        //setLangue_vivante(e.value);
        let _langu= {...langue_vivante} ;
        _langu.id = e.value ;
        setLangue_vivante(_langu) ;

    } ;
    const onStatutChange = (e) => {
        //setLangue_vivante(e.value);
        let _stat= {...statutEleve} ;
        _stat.statut_code = e.value ;
        setstatutEleve(_stat) ;

    } ;
    const onClasseChange = (e) => {
        setClasse(e.value);
    }
    const onPeriodeChange = (e) => {
        setPeriode(e.value);
    }
    const onAnneeChange = (e) => {
        setannee(e.value);
    }

    const onMatiereChange = (e) => {
        setMatiere(e.value);
    }
    const onBrancheChange = (e) => {
        setBranche(e.value);
    }
    const editFile = (file) => {
        setRapport({ ...file });

        const dynamicFunctionName = file.code ;
        console.log("FonctionName "+dynamicFunctionName) ;
        const dynamicFunction = functions[dynamicFunctionName];
        dynamicFunction();
    }
    const onGlobalFilterChange2 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['global'].value = value;

        setFilters2(_filters2);
        setGlobalFilterValue2(value);
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">

                <Button icon="pi pi-file" className="p-button-rounded p-button-success mr-2" onClick={() => editFile(rowData)} />
            </div>
        );
    }
    const renderHeader2 = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Mots clés..." />
                </span>
            </div>
        )
    }
    const header2 = renderHeader2();
    return (
        <div className="grid">
            <div className="col-12" >
                <div className="card">
                    <Panel header="Paramètres obligatoires" toggleable  >
                        <p className="m-0">
                    <div className="formgrid grid">
                        <div className="field col">
                            <h8>Année *</h8>
                            <Dropdown  id="dropdown"   options={annees} value={annee} onChange={onAnneeChange} optionLabel="libelle"  />
                        </div>
                        <div className="field col">
                            <h8>Période*</h8>
                            <Dropdown  id="dropdown"   options={periodes} value={periode} onChange={onPeriodeChange} optionLabel="libelle"  />
                        </div>

                    </div>
                        </p>
                    </Panel>
                    <Panel header="Modèle de rapport par DRENA" toggleable>
                        <div className="flex flex-wrap gap-3">
                            <div className="flex align-items-center">
                                <RadioButton  disabled inputId="modele1" name="rapport" value="Agboville" onChange={(e) => setModeleRapport(e.value)} checked={modeleRapport === 'Agboville'}/>
                                <label htmlFor="modele1" className="ml-2">DREN AGBOVILLE</label>
                            </div>
                            <div className="flex align-items-center">
                                <RadioButton  disabled inputId="modele2" name="rapport" value="Bouake" onChange={(e) => setModeleRapport(e.value)} checked={modeleRapport === 'Bouake'}/>
                                <label htmlFor="modele2" className="ml-2">DREN BOUAKE</label>
                            </div>
                            <div className="flex align-items-center">
                                <RadioButton disabled inputId="modele3" name="rapport" value="Daloa" onChange={(e) => setModeleRapport(e.value)} checked={modeleRapport === 'Daloa'}/>
                                <label htmlFor="modele3" className="ml-2">DREN DALOA</label>
                            </div>
                            <div className="flex align-items-center">
                                <RadioButton disabled inputId="modele8" name="rapport" value="Odienne" onChange={(e) => setModeleRapport(e.value)} checked={modeleRapport === 'Odienne'}/>
                                <label htmlFor="modele4" className="ml-2">DREN ODIENNE</label>
                            </div>
                            <div className="flex align-items-center">
                                <RadioButton disabled inputId="modele8" name="rapport" value="Seguela" onChange={(e) => setModeleRapport(e.value)} checked={modeleRapport === 'Seguela'}/>
                                <label htmlFor="modele4" className="ml-2">DREN SEGUELA</label>
                            </div>
                            <div className="flex align-items-center">
                                <RadioButton  inputId="modele4" name="rapport" value="Yamoussoukro" onChange={(e) => setModeleRapport(e.value)} checked={modeleRapport === 'Yamoussoukro'}  />
                                <label htmlFor="modele4" className="ml-2">DREN YAMOUSSOUKRO</label>
                            </div>
                            <div className="flex align-items-center">
                                <RadioButton disabled inputId="modele5" name="rapport" value="dren1" onChange={(e) => setModeleRapport(e.value)} checked={modeleRapport === 'dren1'}/>
                                <label htmlFor="modele4" className="ml-2">DREN 1</label>
                            </div>
                            <div className="flex align-items-center">
                                <RadioButton disabled inputId="modele6" name="rapport" value="dren2" onChange={(e) => setModeleRapport(e.value)} checked={modeleRapport === 'dren2'}/>
                                <label htmlFor="modele4" className="ml-2">DREN 2</label>
                            </div>
                            <div className="flex align-items-center">
                                <RadioButton inputId="modele7" name="rapport" value="dren3" onChange={(e) => setModeleRapport(e.value)} checked={modeleRapport === 'dren3'}/>
                                <label htmlFor="modele4" className="ml-2">DREN 3</label>
                            </div>
                            <div className="flex align-items-center">
                                <RadioButton disabled inputId="modele8" name="rapport" value="dren4" onChange={(e) => setModeleRapport(e.value)} checked={modeleRapport === 'dren4'}/>
                                <label htmlFor="modele4" className="ml-2">DREN 4</label>
                            </div>
                        </div>
                    </Panel>

                    <Panel header="Paramètres facultatifs" toggleable collapsed={true}>
                        <p className="m-0">
                            <div className="formgrid grid">
                                <div className="field col">
                                    <h8>Classe</h8>
                                    <Dropdown id="dropdown" options={classes} value={classe} onChange={onClasseChange} optionLabel="libelle"/>
                                </div>
                                <div className="field col">
                                    <h8>Matricule {getActeurLabel()}</h8>
                                    <InputText id="matricule" type="text" value={matricule} onChange={(e) => setmatricule(e.target.value)}/>
                                </div>
                                <div className="field col">
                                    <h8>Format excel</h8>
                                    <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)}/>
                                </div>
                            </div>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <h8>Format Decompresser(Bulletin)</h8>
                                    <InputSwitch checked={compress} onChange={(e) => setCompress(e.value)}/>
                                </div>
                                <div className="field col">
                                    <h8>Format école Arabe(Bulletin)</h8>
                                    <InputSwitch checked={bulletinArabe} onChange={(e) => setBulletinArabe(e.value)}/>
                                </div>

                                <div className="field col">
                                    <h8>Supprimer Filigramme(Bulletin)</h8>
                                    <InputSwitch checked={filigramme} onChange={(e) => setFiligramme(e.value)}/>
                                </div>

                                <div className="field col">
                                    <h8>Changer la position du logo(Bulletin)</h8>
                                    <InputSwitch checked={positionLog} onChange={(e) => setPositionLog(e.value)}/>
                                </div>
                                <div className="field col">
                                    <h8>Utiliser le modèle Secondaire avec test lourd</h8>
                                    <InputSwitch checked={testLourd} onChange={(e) => setTestLourd(e.value)}/>
                                </div>
                            </div>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <h8>Pivoter Les photos vers la droite(Bulletin)</h8>
                                    <InputSwitch checked={pivoter} onChange={(e) => setPivoter(e.value)}/>
                                </div>

                                <div className="field col">
                                    <h8>Changer la position "REPUBLIQUE DE COTE D'IVOIRE" (Bulletin)</h8>
                                    <InputSwitch checked={infosAmoirie} onChange={(e) => setInfosAmoirie(e.value)}/>
                                </div>
                                <div className="field col">
                                    <h8>Utiliser le modèle (BTS) (Bulletin)</h8>
                                    <InputSwitch checked={modelePointCarre} onChange={(e) => setModelePointCarre(e.value)}/>
                                </div>
                                <div className="field col">
                                    <h8>Utiliser le modèle Superieur BTS sans signature</h8>
                                    <InputSwitch checked={modelelmd} onChange={(e) => setModelelmd(e.value)}/>
                                </div>

                            </div>
                        </p>
                        <div className="formgrid grid">
                            <div className="field col">
                                <h8>Activer Distinctions(Bulletin)</h8>
                                <InputSwitch checked={distinction} onChange={(e) => setDistinction(e.value)} />
                        </div>
                        </div>
                    </Panel>

                    <Panel header={`Ces paramètres Concernent l'impression de la liste des ${getActeurLabels()}`} toggleable collapsed={true}>
                        <p className="m-0">
                    <div className="formgrid grid">

                        <div className="field col">
                            <h8>Avec photo </h8>
                            <InputSwitch checked={photo} onChange={(e) => setPhoto(e.value)} />
                        </div>
                        <div className="field col">
                            <h8>Redoublant</h8>
                            <Dropdown id="redoubl" options={Redoublants} value={redoublant.id} onChange={onRedoublantChange} optionValue="id"   optionLabel="libelle"  ></Dropdown>
                        </div>
                        <div className="field col">
                            <h8>Sexe</h8>
                            <Dropdown id="sexe" value={selectedSexe.sexe_code} options={Sexe} onChange={onSexeChange} optionLabel="sexe_code" optionValue="sexe_code" placeholder="Selectionnez le Sexe" />

                        </div>
                        <div className="field col">
                            <h8>boursier</h8>
                            <Dropdown id="boursier" options={Boursiers} value={boursier.id} onChange={onBoursierChange}optionLabel="libelle"  optionValue="id"  ></Dropdown>
                        </div>
                        <div className="field col">
                            <h8>Langue vivante </h8>
                            <Dropdown id="langV2" options={LV2S} value={langue_vivante.id} onChange={onLangChange}  optionLabel="libelle" optionValue="id"   ></Dropdown>

                        </div>
                        <div className="field col">
                            <h8>Statut</h8>
                            <Dropdown id="statu" value={statutEleve.statut_code} onChange={onStatutChange}  options={Statuts} optionLabel="statut_code" optionValue="statut_code" placeholder="Selectionnez le statut*" />

                        </div>
                        <div className="field col">
                            <h8>Niveau* </h8>
                            <Dropdown  id="niveau"   options={branches} value={branche} onChange={onBrancheChange} optionLabel="libelle"  >
                            </Dropdown>
                        </div>

                    </div>
                        </p>
                    </Panel>
                    <Panel header="Ces paramètres Concernent l'impression des certificats" toggleable collapsed={true}>
                        <p className="m-0">
                            <div className="formgrid grid">
                                <div className="field col">
                                    <h8>Autre modèle de certificat de fréquentation</h8>
                                    <InputSwitch checked={autreModelFreque} onChange={(e) => setAutreModelFreque(e.value)}/>
                                </div>

                                <div className="field col">
                                    <label htmlFor="sign">Nom signataire</label>
                                    <InputText type="text" id="sign" value={signataire} onChange={(e) => setSignataire(e.target.value)}/>
                                </div>
                                <div className="field col">
                                    <label htmlFor="sign">Fonction signataire</label>
                                    <InputText type="text" id="sign" value={fonction} onChange={(e) => setFonction(e.target.value)}/>
                                </div>
                            </div>
                        </p>
                    </Panel>
                    <Panel header="Ces paramètres Concernent l'impression de  la liste des admis(Par niveau , statut, classe...)" toggleable collapsed={true}>
                        <p className="m-0">
                    <div className="formgrid grid">

                        <div className="field col">
                            <h8>Moyenne superieure à :</h8>
                            <InputNumber id="moy" type="text" value={moyenne} onValueChange={(e) => setmoyenne(e.value)}/>
                        </div>

                    </div>
                        </p>
                    </Panel>
                    <Panel header="Ces paramètres Concernent l'impression de  la liste des moyennes par professeur" toggleable collapsed={true}>
                        <p className="m-0">
                    <div className="formgrid grid">


                        <div className="field col">
                            <h8>Matière (Veuillez sélectionner la classe pour avoir la liste des matières) :</h8>
                            <Dropdown  id="dropdown"   options={matieres} value={matiere} onChange={onMatiereChange} optionLabel="libelle"  >
                            </Dropdown>
                        </div>

                    </div>
                        </p>
                    </Panel>

                </div>
                <div className="col-12" >
                    <div className="card">
                        <div className="flex justify-content-center align-items-center mb-4 gap-2">
                            <ProgressSpinner className={isNotSpinning}  style={{width: '50px', height: '50px'}}  strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                        </div>

                        <DataTable value={rapports} paginator className="p-datatable-customers" rows={10} header={header2} globalFilterFields={['code','nom', 'description']} filters={filters2} filterDisplay="row" >
                            <Column field="code" header="Code"></Column>
                            <Column field="nom" header="Nom du rapport"></Column>
                            <Column field="description" header="Description"></Column>
                            <Column body={actionBodyTemplate}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>



        </div>


    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(DocumentsImprimables, comparisonFn);
